.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 114px;

  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 46px;
    gap: 149px;
  }
  @media (max-width: 580px) {
    margin-top: 0;
  }
  @media (max-width: 320px) {
    gap: 119px;
  }
}
