.wrapper {
  width: 414px;
  height: 578px;
  border-radius: 20px;
  background-color: rgba(20, 21, 22, 0.8);
  z-index: 2;
  padding: 26px 34px 34px 30px;
  margin-bottom: 87px;
  position: relative;
  backdrop-filter: blur(6px);

  @media (max-width: 460px) {
    width: 100%;
    height: 100%;
    padding: 26px 19px 34px 23px;
    margin-bottom: 43px;
  }
}

.title {
  font: 22px/33px var(--visby-semiBold);
  color: #ffffff;
}

.authWrapper {
  margin-top: 27px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formWrapper {
  margin-top: 29px;
}

.formTitle {
  font: 20px/20px var(--visby-semiBold);
  color: #ffffff;
  filter: brightness(60%);
}

.buttonWrapper {
  position: absolute;
  width: 350px;
  bottom: 34px;
  filter: brightness(60%);
  @media (max-width: 460px) {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    position: unset;
  }
}

.highLight {
  filter: brightness(100%);
}
