.wrapper {
  background: #1c1f23;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
}

.wrapperHeader {
  margin-top: 5px;

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
}

.capWrapper {
  @media (min-width: 1499px) {
    display: flex;
    justify-content: center;
    justify-items: center;
    justify-self: center;
  }
}

.largeScreenCap {
  max-width: 984px;
  position: relative;
  width: 100%;
  @media (min-width: 1499px) {
    width: 984px;
  }

  @media (max-width: 960px) {
    position: unset;
  }
}

.largeScreenCapFooter {
  @media (min-width: 1499px) {
    width: 984px;
  }
}

.codelexIconShadow {
  position: absolute;
  top: -42px;
  right: 340px;
  width: 432px;
  height: auto;
  transform: rotate(15deg);
  overflow: visible;
  filter: drop-shadow(0 0 32px #00fca3);
  path {
    fill: #00fca3;
    opacity: 0.1;
    filter: blur(6px);
  }

  @media (min-width: 1499px) {
    top: 64px;
    right: 340px;
  }

  @media (max-width: 960px) {
    top: 320px;
    right: unset;
    left: 40px;
    overflow: unset;
  }

  @media (max-width: 580px) {
    transform: scale(0.6) rotate(15deg);
    top: 130px;
    left: -55px;
  }
  @media (max-width: 320px) {
    top: 110px;
    left: -75px;
  }
}

.codelexIconFront {
  position: absolute;
  top: -76px;
  right: 191px;
  width: 465px;
  height: auto;
  transform: rotate(15deg);
  overflow: visible;
  filter: drop-shadow(0 0 32px #00fca3);
  path {
    fill: #00fca3;
  }

  @media (min-width: 1499px) {
    top: 39px;
    right: 191px;
  }

  @media (max-width: 960px) {
    top: 280px;
    right: unset;
    left: 120px;
  }

  @media (max-width: 580px) {
    transform: scale(0.6) rotate(15deg);
    top: 100px;
    left: 0px;
  }
  @media (max-width: 320px) {
    top: 80px;
    left: -20px;
  }
}

.spaceBetween {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.codelexIconShadowOnly {
  position: absolute;
  top: -76px;
  right: 140px;
  width: 432px;
  height: auto;
  transform: rotate(15deg);
  overflow: visible;
  filter: drop-shadow(0 0 32px #00fca3);
  path {
    fill: #00fca3;
    opacity: 0.1;
    filter: blur(6px);
  }

  @media (min-width: 1499px) {
    top: 34px;
    right: 200px;
  }

  @media (max-width: 960px) {
    top: 260px;
    right: unset;
    left: 40px;
    overflow: unset;
  }

  @media (max-width: 580px) {
    transform: scale(0.6) rotate(15deg);
    top: 130px;
    left: -55px;
  }
  @media (max-width: 320px) {
    top: 110px;
    left: -75px;
  }
}
